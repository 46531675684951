import React, { Fragment,useRef,useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import { Translation } from 'react-i18next';

// helpers
import {history} from '../../_helpers';
import { toggleLang, setLang} from '../../actions/lang';
import { switchLangReload } from "../../services/lang";


const NavBar = (props) => {

    const {location} = props;
    const linksRef = useRef();
    const closeRef = useRef();
    const [isLinkShow,togglelink] = useState(true);
    const linkClickHandler = (e) => {
        toggleNavBar();
        history.push(e.target.name)
    }

    // useEffect(() => {
    //     if (location.pathname == '/login-cn'){
    //         props.setLang('cn');
    //     }
    // },[]);

    if(location.pathname.includes('/medical/records')){
        return null;
    }

    const toggleNavBar = () => {

        // linksRef.current.style.display = "block"
        linksRef.current.childNodes.forEach(node => {
            // console.log(node.style.display)
            if(!node.style.display || node.style.display=="none")
                node.style.display = "block";
            else if(node.style.display=="block")
                node.style.display= "none";
        })

        

        if(!closeRef.current.style.display || closeRef.current.style.display=='none'){
            closeRef.current.style.display = 'block'
        }else if(closeRef.current.style.display=='block'){
            closeRef.current.style.display='none'
        }
        // console.log()
        
    //    if(linksRef.current.className === "nav-bar-links") {
    //     linksRef.current.className = "display-block";
    //    }else if(linksRef.current.className === "display-block"){
    //     linksRef.current.className = "display-none"
    //    }else if(linksRef.current.className === "display-none") {
    //     linksRef.current.className = "display-block"
    //    }
        
    }

    return (

        <Fragment>
            <nav id="main-nav">
                <div className="logo">
                    <Link to="/" name="/" onClick={linkClickHandler}>Richmond In-Store Medical Clinic <i className="fas fa-hospital"></i></Link>
                </div>
                <ul>
                
                    <div className="nav-bars-icon">
                        <div><Link to="/" name="/" onClick={linkClickHandler}>Richmond In-Store Medical Clinic <i className="fas fa-hospital"></i></Link></div>
                        <a href="#" onClick={toggleNavBar}><i className="fas fa-bars"></i></a>
                        <a ref={closeRef} className="pull-right close-icon" href="#" onClick={toggleNavBar}><i className="fas fa-times"></i></a>
                    </div>

                    <section ref={linksRef} id="nav-bar-links" className="nav-bar-links" >

                        <li >
                            <Link name="/" className={location.pathname == '/' ? 'current' :''} to="/" onClick={linkClickHandler} >               
                                <Translation>
                                {
                                    t => <>{t('HOME')}</>
                                }
                                </Translation>
                            </Link></li>

                        <li ><Link name="/policies" className={location.pathname == '/policies' ? 'current' :''} to="/policies" onClick={linkClickHandler}>
                            <Translation>
                                {
                                    t => <>{t('CLINIC POLICIES')}</>
                                }
                            </Translation>
                        </Link></li>

                        <li><Link name="/faq" className={location.pathname == '/faq' ? 'current' :''} to="/faq" onClick={linkClickHandler}>
                            <Translation>
                                {
                                    t => <>{t('FAQ')}</>
                                }
                            </Translation>
                        </Link></li>
                        <li><a href="https://southvanmed.com/immigration">
                            <Translation>
                                {
                                    t => <>{t('IMMIGRATION MEDICAL EXAM')}</>
                                }
                            </Translation>
                            <br/>
                             At the South Vancouver Medical Clinic
                        </a></li>

                        <li><Link name="/bookappointment" className={ location.pathname == '/bookappointment' ? 'current' :''} to="/bookappointment" onClick={linkClickHandler}>
                            <Translation>
                                {
                                    t => <>{t('BOOK APPOINTMENT')}</>
                                }
                            </Translation>
                        </Link></li>

                        <li><Link name="/login" className={ location.pathname == '/login' ? 'current color-red' :'color-red'} to="/login" onClick={linkClickHandler}>
                            <Translation>
                                {
                                    t => <>{t('LOGIN')}</>
                                }
                            </Translation>
                        </Link></li>

                        <li><Link name="/register" className={ location.pathname == '/register' ? 'current  color-red' :' color-red'} to="/register" onClick={linkClickHandler}>
                            <Translation>
                                {
                                    t => <>{t('REGISTER')}</>
                                }
                            </Translation>
                        </Link></li>
                       <li><button className="btn btn-uni" style={{float: "right"}} onClick={()=>{switchLangReload()}}>{ props.isEn ? "中文" : "EN"}</button></li>
                    </section>
                    
                    


                </ul>
            </nav>
        </Fragment>
    )
}


const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        // alertMessage: state.auth.alertMessage
        isEn: state.lang.lang == "en" || typeof state.lang.lang == "undefined"
    };
}


const mapDispatchToProps = {
    toggleLang,
    setLang
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);