import React,{lazy,Suspense} from 'react';

// components
const TwitterContainer = lazy(()=> import ('./layout/TwitterContainer'));


import Anouncement from './Anouncement';


const MyMapComponent = lazy(()=> import ('./layout/GoogleMapContainer'));
import PropagateLoader from './layout/PropagateLoader';

// libs 
import {Helmet} from 'react-helmet'


// assets
import step1 from '../assets/img/howtogethere/step1-min.webp';
import step2 from '../assets/img/howtogethere/step2-min.webp';
import step3 from '../assets/img/howtogethere/step3-min.webp';
import step4 from '../assets/img/howtogethere/step4-min.webp';
import steering from '../assets/img/icons/steering5.png';
import { scroller } from 'react-scroll';
import {Link} from 'react-router-dom';


class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isHidden :{
                sickNote:true,
                labtest:true,
                med:true,
                driver:true,
                inPersonApp:true,
                phoneApp:true,
                icbcApp:true,
                privateApp:true


            }
        }
    }

    componentDidMount() {
        this._scrollToTop();
    }

    _scrollToTop(){
        scroller.scrollTo("showcase", {
            duration: 800,
            smooth: true,
            offset: -50
        });

    }

    learnMoreHandler(e){
        console.log('learnMoreHandler',e.target.name)
        this.setState({
            isHidden:{
                ...this.state.isHidden,
                [e.target.name]:!this.state.isHidden[e.target.name]
            }
        })
    }

    renderShowcase() {
        return (
            <>
                <section id="showcase" className="lazyload">
                    <div className="dark-overlay">
                        <div className="">
                            <div className="showcase-content">
                                <h1 className="f-3">Welcome to Richmond In-Store Medical Clinic</h1>
                                <p className="f-2">
                                    We offer <span className="keywords">URGENT CARE</span> <span> & </span>
                                    <span className="keywords">FAMILY MEDICINE</span> services
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }

    // renderTwitter() {
    //     return (
    //         <>
    //             <section id="twitter">
    //                 <div className="twitterborder">
    //                     <a className="twitter-timeline tw-align-center" href="https://twitter.com/instoremed" data-theme="dark" data-limit={3} data-width="1200">Tweets by @InStoremed</a>

    //                 </div>

    //             </section>

    //         </>
    //     )
    // }

    renderServices() {

        // driver:true,
        //         inPersonApp:true,
        //         phoneApp:true,
        //         icbcApp:true,
        //         privateApp:true

        let {sickNote,labtest,med,driver,inPersonApp,phoneApp,icbcApp,privateApp} = this.state.isHidden;
        let sickNoteClass = sickNote ? 'mt-2 keywords ':'mt-2 keywords show';
        let labtestClass = labtest ? 'mt-2 keywords':'mt-2 keywords show';
        let medClass = med ? 'mt-2 keywords ':'mt-2 keywords show';
        let driverClass = driver ? 'mt-2 keywords ':'mt-2 keywords show';
        let inPersonAppClass = inPersonApp ? 'mt-2 keywords ':'mt-2 keywords show';
        let phoneAppClass = phoneApp ? 'mt-2 keywords ':'mt-2 keywords show';
        let icbcAppClass = icbcApp ? 'mt-2 keywords ':'mt-2 keywords show';
        let privateAppClass = privateApp ? 'mt-2 keywords ':'mt-2 keywords show';

        return (
            <>
                <section id="services">
                 
                    <h2 className="f-3">Our <span className="keywords"> Services</span></h2>
      <div className="bottom-line"></div>
      <p className="lead">
        <span className="keywords show-text">URGENT CARE</span> & <span className="keywords show-text">FAMILY MEDICINE</span>
      </p>
                    <div className="patients-cat-1">
                        <div>
                                <i className="fas fa-clinic-medical fa-3x"></i>
                                <h3 className="title"> 1) In Person Doctor Appointment </h3>

                                <p className="mt-2 ">
                                 Patients who want to see their <span className="keywords show-text"><strong>doctor at the medical clinic.</strong></span>
                                 </p>

                                {/* <button className="learn-more" name="sickNote" onClick={this.learnMoreHandler.bind(this)}>{sickNote ? 'Learn more' : 'Collapse'}</button> */}
                                <Link to='/login' className="btn btn-app mt-2">Book In Person Doctor Appointment </Link>

                        </div>

                        <div>
                            <i className="fas fa-pills fa-3x"></i>
                            <h3 className="title"> 2) Medication Refill</h3>
                            <p className={medClass}>
                            For simple prescription refills<br /><br />
                            <span className={med ? '':'show-text'}>Not for stimulants (ADHD medications), opiates (pain pills) or benzodiazepines (sleeping pills)<br /><br />
                                        Only if 19-year-old or older<br /><br /></span>
                            </p>
                            <button className="learn-more" name="med" onClick={this.learnMoreHandler.bind(this)}>{med ? 'Learn more' : 'Collapse'}</button>
                            <Link to='/login' className="btn btn-app mt-2">Refill Now</Link>
                        </div>
                    </div>

                    <div className="patients-cat-3">

                        <div>
                            <i className="fab fa-canadian-maple-leaf fa-3x"></i>
                            <h3 className="title"> 3) Immigration Medical Exam at the South Vancouver Medical Clinic</h3>
                            <p className="mt-2 ">
                                Patients who require an 
                                <span className="keywords show-text"> <strong>&nbsp;immigration medical exam.</strong></span>
                            </p>
                            <button className="learn-more" name="phoneApp" onClick={this.learnMoreHandler.bind(this)}>{phoneApp ? 'Learn more' : 'Collapse'}</button>
                            <Link to='/immigration' className="btn btn-app mt-2">Book Immigration Medical Examination</Link>
                            
                        </div>
                        
                        <div>
                            <i className="fas fa-phone fa-3x"></i>
                            <h3 className="title"> 4) Telephone Doctor Appointment</h3>
                            <p className="mt-2 ">
                                Patients who want their <span className="keywords show-text"><strong>family doctor</strong></span> to call them back.
                            </p>
                            <Link to='/login' className="btn btn-app mt-2">Book Telephone Consultation </Link>
                        </div>
                    </div>

                </section>
            </>
        )
    }

    renderHowtogethere() {
        return (
            <>
                <section id="nav-here">
                    <div className="container">
           
 

                        <h2 className="f-3 center p-2">
          How to get to our 
          <span className="keywords"><strong> clinic</strong></span>
        </h2>
        <div className="bottom-line"></div>




                        <div className="nav-here-steps mb-3">
                            <article className="card">
                                <div>
                                    <h3><span className="keywords f-3">One</span></h3>
                                    <p className="font-it">Arrive to the Superstore main entrance from the parking lot.</p>
                                    <img className="mt-2 lazyload" src={step1} alt="step1" />
                                </div>
                            </article>

                            <article className="card">
                                <div>
                                    <h3><span className="keywords f-3">Two</span></h3>
                                    <p className="font-it">As you get to the top of the long walkway, turn to the right towards the pharmacy.</p>
                                    <img className="mt-2 lazyload" src={step2} alt="step2" />
                                </div>
                            </article>

                            <article className="card">
                                <div>
                                    <h3><span className="keywords f-3">Three</span></h3>
                                    <p className="font-it">
                                        The medical clinic is next to the pharmacy
                                    </p>
                                    <img className="mt-2 lazyload" src={step3} alt="step3" />
                                </div>
                            </article>

                            <article className="card">
                                <div>
                                    <h3><span className="keywords f-3">Four</span></h3>
                                    <p className="font-it">You have arrived!</p>
                                    <img className="mt-2 lazyload" src={step4} alt="step4" />
                                </div>
                            </article>
                        </div>
                        <div className="bottom-line-long"></div>
                    </div>
                </section>
            </>
        )
    }

    renderMap(){
        return (
            <>
                <section id="map-container">
                    <h2 className="f-3">Our <span className="keywords"> Location</span></h2>
                    <div className="bottom-line"></div>
                    <div id="map" className="lazyload"></div>
                </section>
            </>
        )
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Medical clinic in Vancouver | Vancouver doctor | Richmond In-Store Medical Clinic</title>
                    <meta name="description" content="TThe Richmond In-Store Medical Clinic provides in-person and online medical services.
                    We offer online booking of doctor appointments, general surgery care and other family medicine services." />
                
                    <meta name="keywords" content="Richmond walk in clinics , Richmond walk in clinic superstore, medical clinic in superstore,
                    walk in clinic richmond,medical clinic in superstore, Richmond In-Store Medical Clinic, walk in clinic in
                    Richmond bc , Richmond bc walk in clinics, walk in clinics Richmond bc, walk in clinic superstore
                    hours, medical centres richmond,medical clinic in Richmond bc, family doctor richmond bc, family
                    doctors in richmond bc,online doctor appointment booking,primacy walk in clinic,real canadian
                    superstore clinic,superstore walk in clinic Vancouver, richmond family medical" />
    
                    <link rel="canonical" href="https://instoremed.com" />

                </Helmet>
                {this.renderShowcase()}
                {this.renderServices()}
                <Suspense fallback={<PropagateLoader isLoading={true}/>}>
                    <Anouncement />
                </Suspense>
                {this.renderHowtogethere()}
                {/* {this.renderMap()} */}
                <Suspense fallback={<PropagateLoader isLoading={true}/>} >
                    <MyMapComponent 
                        isMarkerShown={true}
                        googleMapURL="https://maps.googleapis.com/maps/api/js?callback=google_maps_init&key=AIzaSyAp9OoniExWg61t4S2hAbswaXquVpOBItc"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `500px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                </Suspense>
            </>
        )
    }
}

export default Home;